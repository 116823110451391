import "./firebase";

import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import { Routes, Route, HashRouter } from "react-router-dom";
import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import { AppContext, environment } from "./components/AppContext";

import './index.css';

import Page from './Page';
import { Build } from "./components/Build";
import { Landing } from "./components/Landing";
import { LoginEmailLink } from "./components/auth/LoginEmailLink";
import { getAuth } from "firebase/auth";
import { LoginLink } from "./components/auth/LoginLink";
import { LoginEmailPassword } from "./components/auth/LoginEmailPassword";
import { NoMatch } from "./components/NoMatch";

// import "firebase/app-check";

// https://dev.to/farazamiruddin/react-firebase-add-firebase-to-a-react-app-4nc9
// Use your config values here.
// firebase.analytics();
// const appCheck = firebase.appCheck();
// Pass your reCAPTCHA v3 site key (public key) to activate(). Make sure this
// key is the counterpart to the secret key you set in the Firebase console.
// appCheck.activate(
//   '6Lem59AbAAAAABeJP-Hg0NktdlMuphvJhZkO6qAp',
//   // Optional argument. If true, the SDK automatically refreshes App Check
//   // tokens as needed.
//   true);

// function NoMatch() {
//   return (
//     <div>
//       <h2>Nothing to see here!</h2>
//       {/* <p>
//         <Link to="/">Go to the home page</Link>
//       </p> */}
//     </div>
//   );
// }

const uri = (() => {  
  if (environment === 'emulator') {
    // todo [ahmed.kamel] hardcoded project id and region
    return 'http://emulator.xyz:5001/dotrun-52489/us-central1/route/graphql/';
  }

  return 'https://foobar.run/graphql/';
})();

const auth = getAuth();
const httpLink = createHttpLink({ uri });
const authLink = setContext(async (_, { headers: h }) => {
  const user = auth.currentUser;

  const headers = { ...h };

  // get the authentication token from local storage if it exists
  if (user) {
    // const token = await (async () => {
    //   if (user.getIdToken) {
    //     return user.getIdToken();
    //   }

    //   // todo [ahmed.kamel] on .build the user will be a plain object
    //   // ^^ not calling getIdToken will result in stale token
    //   return (user as unknown as any)["stsTokenManager"]["accessToken"];
    // })();
    const token = await user.getIdToken();

    // const token = await user.getIdToken();
    if (token) {
      headers['authorization'] = `Bearer ${token}`;
    }
  }
  return { headers }
});

// todo [ahmed.kamel] kill cache on logout
// The server can use that header to authenticate the user and attach it to the GraphQL execution context, so resolvers can modify their behavior based on a user's role and permissions.
// https://www.apollographql.com/docs/react/networking/authentication/
const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});

const login = (() => {
  if (environment === 'emulator') {
    return (
      <React.Fragment>
        <Route path="/login" element={<LoginEmailPassword />}  />
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <Route path="/login" element={<LoginEmailLink client={client} />}  />
      <Route path="/loginLink" element={<LoginLink emailLink={window.location.href} />}  />
    </React.Fragment>
  )
})();

ReactDOM.render(
  <React.StrictMode>
    {/* <!-- The core Firebase JS SDK is always required and must be listed first --> */}
    {/* <script src="https://www.gstatic.com/firebasejs/8.8.1/firebase-app.js"></script> */}

    {/* <!-- TODO: Add SDKs for Firebase products that you want to use
        https://firebase.google.com/docs/web/setup#available-libraries --> */}
    {/* <script src="https://www.gstatic.com/firebasejs/8.8.1/firebase-analytics.js"></script> */}

    {/* <script
      dangerouslySetInnerHTML={{ __html:
        `
        firebase.initializeApp({{ 
          apiKey: "AIzaSyChWm4J2AXRqodOL8IGqtv22aOrBE-ZbsM",
          authDomain: "dotrun-52489.firebaseapp.com",
          projectId: "dotrun-52489",
          storageBucket: "dotrun-52489.appspot.com",
          messagingSenderId: "624174201317",
          appId: "1:624174201317:web:9c3481dc6e42cf395a970c",
          measurementId: "G-5QPRKL5WEL"
        }});
        firebase.analytics();
        `
      }}
    /> */}

    <AppContext.Provider value={{ environment }}>
      <HashRouter>
        <Routes>
          <Route path="/" element={<Page />}>
            <Route index element={<Landing />} />
            { login }
            <Route path="/build/:host/:owner/:name" element={<Build client={client}/>} />
            <Route path="*" element={<NoMatch />} />
          </Route>
        </Routes>
      </HashRouter>
    </AppContext.Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
