import { getAuth, signInWithEmailAndPassword } from "firebase/auth";

import { useNavigate } from 'react-router-dom';
import { EmailPasswordDialog } from "./EmailPasswordDialog";

const auth = getAuth();

export function LoginEmailPassword() {
  const navigate = useNavigate();

  const onCancel = () => {
    navigate(-1);
  }

  const passwordSignIn = async (email: string, password: string) => {
    // todo [ahmed.kamel] handle error
    const creds = await signInWithEmailAndPassword(auth, email, password);

    navigate(-1);
  }

  return (
    <EmailPasswordDialog onConfirm={passwordSignIn} onCancel={onCancel}></EmailPasswordDialog>
  );
}