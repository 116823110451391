import React from "react";

import TextTransition, { presets } from "react-text-transition";
import { Box, Chip, Container, Grid, ListItem, Paper, Stack, TextField, Typography } from "@mui/material";
// import { makeStyles, styled } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { Limits } from "./landing/limits";
import { Examples } from "./landing/examples";
import { Image } from 'mui-image'

const DotCom: React.FunctionComponent = (props) => {
  return (
    <Box component="span" m={0} style={{backgroundColor: '#FFF300', alignSelf: 'flex-start'}}>
      {props.children}
    </Box>
    );
}

const ListItemLink: React.FunctionComponent<{href: string}> = (props) => {
  return <ListItem button component="a" {...props} />;
}

export function Landing() {
  // const [url, setUrl] = React.useState("https://github.com/a7medkamel/dotrun-example");
  // const [runUrl, setRunUrl] = React.useState("https://github.run/a7medkamel/dotrun-example");
  // const [buildUrl, setBuildUrl] = React.useState("https://github.build/a7medkamel/dotrun-example");
  const [index, setIndex] = React.useState(0);
  const [index_hosts, setIndexHosts] = React.useState(0);

  React.useEffect(() => {
    const intervalId = setInterval(() =>
      setIndex(index => index + 1),
      4000 // every 4 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);

  React.useEffect(() => {
    const intervalId = setInterval(() =>
      setIndexHosts(index_hosts => index_hosts + 1),
      4000 // every 4 seconds
    );
    return () => clearTimeout(intervalId);
  }, []);
  
  // const classes = useStyles();

  // const onRepositoryChange: React.ChangeEventHandler<HTMLInputElement>
  // = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const repo = event.target.value;
  //   // setUrl(repo);
  //   // setRunUrl(repo.replace('.com', '.run'));
  //   // setBuildUrl(repo.replace('.com', '.build'));
  // }

  // const TEXTS = [
  //   "foobar.run",
  // ];

  // const TEXTS = [
  //   ".com",
  //   ".run",
  //   ".build",
  // ];

  // const HOSTS = [
  //   "github",
  //   "gitlab",
  //   "bitbucket",
  // ];

  const Item = styled(Paper)(({ theme }) => ({
    // ...theme.typography.body2,
    // padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  return (
    <Container maxWidth="lg" sx={{marginTop: "4rem"}}>
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Stack spacing={4}>
            <Box>
              <Image src="/mole.png" width="400px" height="300px" style={{"objectPosition": "0px 0px", "objectFit": "scale-down"}}></Image>
              {/* <Typography variant="h1" sx={{borderLeft: 7, borderColor: "rgb(227, 113, 119)", padding: "5px 20px"}}>
                <TextTransition
                  text={TEXTS[index % TEXTS.length]}
                  springConfig={ presets.gentle }
                />
              </Typography> */}
            </Box>
            <Box>
              <Stack direction="row" spacing={2}>
              {[
                  "Bots",
                  "IoT",
                  "Hackathon",
                  "School Project",
                  "Side Project",
                  "Portfolio",
                  "Open Source",
                  "Demo",
                  "Personal Page",
                ].map((value) => {
                  return (
                    <Chip key={value} label={value} />
                  );
                })}
              </Stack>
            </Box>
            <Box>
              <Typography variant="subtitle1" sx={{fontSize: "1.3rem"}}>If your repository has a Dockerfile, we will build and deploy it.</Typography>
              <Typography variant="subtitle1" sx={{fontSize: "1.3rem"}}>Just replace .com with .run in your repository URL.</Typography>
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h3" sx={{ fontWeight: 'bold' }}>README</Typography>
          <Typography variant="subtitle1">We will build and deploy your repository the first time you try to access it through it's .run url.</Typography>
          <Typography variant="subtitle1">Your container will listen to HTTP requests at it's .run url</Typography>
          <Typography variant="subtitle1">Visit .build to debug and manage your deployment.</Typography>
          <Typography variant="subtitle1">Your Dockerfile needs to have an EXPOSE instruction listing your container's open port.</Typography>
        </Grid>
        <Grid item xs={8}>
          <Typography variant="h3" sx={{ fontWeight: 'bold' }}>EXAMPLES</Typography>
          <Examples />
          {/* <Paper>
          </Paper> */}
        </Grid>
        {/* <Grid item xs={6}>
          <Box>
            <TextField 
              fullWidth 
              id="repo" 
              label="Repository" 
              variant="outlined" 
              size="small" 
              value={url} 
              onChange={onRepositoryChange} />
          </Box>
        </Grid> */}
        <Grid item xs={6}>
          <Typography variant="h3" sx={{ fontWeight: 'bold' }}>LIMITS</Typography>
          <Limits />
        </Grid>
        <Grid item xs={6}>
          <iframe src="https://discord.com/widget?id=935487195500912671&theme=dark" width="350" height="300" allowTransparency frameBorder="0" sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"></iframe>
        </Grid>
      </Grid>
    </Container>
  );
}