import { ApolloClient, gql, NormalizedCacheObject, useQuery } from "@apollo/client";
import { Box, LinearProgress, makeStyles, Typography } from "@material-ui/core";
import React, { useEffect } from "react";
import { LogResponse } from "../generated/graphql";


const useLogStyles = makeStyles({
  // logEntry: {
  //   display: "block"
  // },
  waiting: {
    textAlign: "center"
  },
  logsBox: {
    maxHeight: 200,
    overflow: 'auto',
    maxWidth: "30rem"
  }
});

const GET_LOG = gql`
  query fct($id: String!) {
    log(id: $id) {
      url
    }
  }
`;

type LogProps = {
  id: String | undefined;
  client: ApolloClient<NormalizedCacheObject>;
};

export function Log({ id, client }: LogProps) {
  const [url, setUrl] = React.useState<string | null>(null);
  const [text, setText] = React.useState<string | null>(null);

  useEffect(() => {
    if (url && !text) {
      fetch(url).then(async res => {
        const text = await res.text();
        setText(text);
      });
    }
  });

  const classes = useLogStyles();

  const { loading, error, data } = useQuery<{ log: LogResponse }>(GET_LOG, { 
    // client, pollInterval: ms('10s'), variables: { type, id }
    client, variables: { id }
  });

  function Content() {  
    // if log text is ready
    if (text) {
      return (
        <Box sx={{ fontFamily: 'Monospace' }} className={classes.logsBox}>
          <Typography component="pre" variant="caption">
            {text}
          </Typography>
        </Box>
      );
    }

    // if loading
    if (loading) {
      return(
        <Box mt={2}>
          <LinearProgress />
        </Box>
      )
    }

    // if loaded but url is empty
    if (!data?.log) {
      return (
        <Typography component="div" variant="caption" className={classes.waiting}>
          {"logs not found."}
        </Typography>
      );
    }

    // if loaded, set url
    setUrl(data.log.url);

    return(
      <Box mt={2}>
        <LinearProgress />
      </Box>
    )
  }
  
  return (
    <React.Fragment>
      <Box mt={1} mb={1}>
        <Content />
      </Box>
    </React.Fragment>
  )
}