// import "./Build.css";
// import logo from "./logo.svg";

import React from "react";
import ms from "ms";
import { formatRFC7231 } from 'date-fns'
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import TelegramIcon from '@material-ui/icons/Telegram';
import LockIcon from '@mui/icons-material/Lock';
import ConstructionIcon from '@mui/icons-material/Construction';

// import PropTypes from 'prop-types';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import BuildIcon from '@material-ui/icons/Build';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
// import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import FlightTakeoffIcon from '@material-ui/icons/FlightTakeoff';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import TimerOffIcon from '@material-ui/icons/TimerOff';
import HelpIcon from '@material-ui/icons/Help';

import Alert, { AlertProps, Color } from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { ApolloClient, gql, NormalizedCacheObject, useMutation, useQuery } from "@apollo/client";
import { JournalResponse, Maybe, RunKind, RunState, TimelineEntry, AllocateResponse } from "../generated/graphql"
import { Button, CircularProgress, LinearProgress, Typography } from "@material-ui/core";
import { Badge, Divider, Stack } from "@mui/material";

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { useLocation, useParams } from "react-router-dom";
import { getAuth, User } from "firebase/auth";
import { Logs } from "./Logs";
import { Log } from "./Log";
// import { onAuthStateChanged } from "./AppContext";

const useAlertStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const QUERY_JOURNAL = gql`
  query fct($remote: String!, $branch: String!) {
    journal(remote: $remote, branch: $branch) {
      target {
        remote
        branch
      }
      state
      details {
        kind
      }
      build {
        at
        locked
        id
      }
      timeline {
        id
        timestamp
        data {
          kind
        }
      }
    }
  }
`;

const MUTATION_ALLOCATE = gql`
  mutation fct($remote: String!, $branch: String) {
    allocate(remote: $remote, branch: $branch) {
      target {
        remote
        branch
        __typename
      }
    }
}
`;

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '6px 16px',
  },
  secondaryTail: {
    backgroundColor: theme.palette.secondary.main,
  },
}));

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function timestamp(entry: Maybe<TimelineEntry>) {
  const timestamp = (entry as TimelineEntry).timestamp;
  
  return formatRFC7231(timestamp);
}

function Row({ entry: row, journal, client, showLogs }: { entry: JournalResponse['timeline'][0], journal: JournalResponse, client: ApolloClient<NormalizedCacheObject>, showLogs: boolean }) {
  // const { row } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow className={classes.root} hover={true}>
        {showLogs ? <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell> : <TableCell/> }
        <TableCell component="th" scope="row">
          {row?.data.kind}
        </TableCell>
        <TableCell align="right">{timestamp(row)}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {/* <Logs buildId={journal.build?.id} client={client}/> */}
            <Log id={row?.id} client={client}/>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function BuildStatus({ journal }: { journal: JournalResponse }) {
  const classes = useAlertStyles();

  if (journal) {
    const severity: Color = (() => {
      switch(journal.state) {
        case RunState.InProgress:
          return "info";
        case RunState.Completed:
          return "success";
        case RunState.Failed:
        case RunState.Abandoned:
          return "error";
      }

      return "info";
    })();

    const progress = (() =>{
      if (journal?.state === RunState.InProgress) {
        return <LinearProgress />;
      }

      return (null);
    })();

    const byline = (() =>{
      if (journal.build?.at) {
        return  (
        <Typography variant="caption" display="block" gutterBottom>
          Last built and deployed at {formatRFC7231(journal.build?.at)}
        </Typography>
        );
      }

      return (null);
    })();

    return (
      <div>
        <div className={classes.root}>
          <Alert severity={severity}>
            <AlertTitle>Deployment {journal.state}</AlertTitle>
            {journal.target.remote} #{journal.target.branch}
            <Box>{byline}</Box>
          </Alert>
        </div>
        {progress}
      </div>
    );
  }

  return (null);
}

function ReadyStatus({ journal }: { journal: JournalResponse }) {
  if (journal) {
    const ready = (() =>{
      if (journal?.state === RunState.Completed) {
        const href = journal.target.remote.replace("github.com", "foobar.run");

        return (
          <React.Fragment>
            <Button endIcon={<TelegramIcon />} variant="contained" color="primary" href={href}>
              Ready at .run
            </Button>
          </React.Fragment>
        );
      }

      return (<React.Fragment></React.Fragment>);
    })();

    return (ready);
  }

  return (<React.Fragment></React.Fragment>);
}

function AnonymousStatus({ user }: { user : User | null}) {
  const classes = useAlertStyles();

  if (user) {
    return null;
  }

  return (
    <div>
      <div className={classes.root}>
        <Alert severity={"warning"}>
          <AlertTitle>Anonymous</AlertTitle>
          Login to perform manual builds and redeployments of this container.
        </Alert>
      </div>
    </div>
  );
}

// export default Build;
export type BuildProps = {
  client: ApolloClient<NormalizedCacheObject>;
  remote: string;
  branch: string;
};

function ManualBuild({ client, remote, branch, build, user }: BuildProps & { build: JournalResponse['build'], user: User | null }) {
  const [allocate] = useMutation<AllocateResponse>(MUTATION_ALLOCATE, { client });

  const locked = build?.locked === true || !user;

  // todo [ahmed.kamel] send user token to allocate function
  return (
    <Button 
      endIcon={<ConstructionIcon />} 
      variant="text" color="primary" disabled={locked}
      onClick={async () => { allocate({ variables: { remote, branch } }); }}>
      Re-Build
    </Button>
  );
}

function JournalError() {
  const classes = useAlertStyles();

  return (
    <div>
      <div className={classes.root}>
        <Alert severity="error">
          <AlertTitle>Journal Empty</AlertTitle>
        </Alert>
      </div>
    </div>
  );
}

export function Build({ client }: { client: ApolloClient<NormalizedCacheObject> }) {
  // todo [ahmed.kamel] user is tracked in different components
  const auth = getAuth();

  // const [user, setUser] = React.useState(auth.currentUser);
  const [user, setUser] = React.useState<User | null>(null);
  React.useEffect(() => {
    if (auth.currentUser) {
      setUser(auth.currentUser);
    }
    
    auth.onAuthStateChanged((user) => {
      setUser(user);
    });
    // onAuthStateChanged(setUser);
  });

  let { owner } = useParams<"owner">();
  let { name } = useParams<"name">();

  // let location = useLocation();

  const { remote, branch } = ((origin: string) => {
    // const match = matchPath("/:owner/:name", url);
    // const { owner, name } = match.params;
  
    const remote = (() => {
      if (origin.startsWith(`https://gitlab.build`)) {
        return `https://gitlab.com/${owner}/${name}`;
      }
      
      if (origin.startsWith(`https://bitbucket.build`)) {
        return `https://bitbucket.com/${owner}/${name}`;
      }
      
      return `https://github.com/${owner}/${name}`;
    })();
    
    return { remote, branch: "" };
  })(window.origin);

  const { loading, error, data } = useQuery<{ journal: JournalResponse }>(QUERY_JOURNAL, { 
    client, pollInterval: ms('5s'), variables: { remote, branch }
  });

  function Content() {
    const classes = useStyles();

    if (loading) {
      return(
        <Box mt={2}>
          <LinearProgress />
        </Box>
      )
    }

    if (error) {
      return (
        <Box mt={2}>
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            {error.message}
          </Alert>
        </Box>
      )
    }

    const timeline = data?.journal.timeline || [];

    if (!data) {
      // todo [ahmed.kamel] other boxes above are inline
      return JournalError();
    }

    /**
     * Determine whether or not the entry should have a logs display.
     * Currently we only support build logs for the latest build.
     * But in the future we may support deploy logs, multiple build logs, etc.
     *
     * @param index the index of the timeline entry to check
     * @param timeline the array of timeline entries
     */
    const showLogs = (index: number, timeline: Maybe<TimelineEntry>[]): boolean => {
      // const isLatestBuild = () => {
      //   for (let i = timeline.length - 1; i >= 0; i--) {
      //     if (timeline[i]?.data.kind === RunKind.Build) {
      //       if (i == index) return true;
      //       return false;
      //     }
      //   }

      //   return false;
      // }

      // return isLatestBuild();
      return timeline[index]?.data.kind === RunKind.Build
    };

    return (
      <React.Fragment>
        <Box mt={2}>
          <BuildStatus journal={data.journal} />
        </Box>
        <Box mt={2}>
          <AnonymousStatus user={user} />
        </Box>
        <Box mt={2}>
          <Stack spacing={2} direction="row">
            <ReadyStatus journal={data.journal} />
            <ManualBuild client={client} remote={remote} branch={branch} build={data.journal.build} user={user} />
          </Stack>
        </Box>
        <Box mt={2}>
          <TableContainer component={Paper}>
            {/* <Table aria-label="collapsible table"> */}
            <Table aria-label="table">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Step</TableCell>
                  <TableCell align="right">Start Time</TableCell>
                  {/* <TableCell align="right">Duration</TableCell> */}
                  {/* <TableCell align="right">Carbs&nbsp;(g)</TableCell>
                  <TableCell align="right">Protein&nbsp;(g)</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {timeline.map((row, index) => (
                  // {icon(entry)}
                  <Row key={row?.timestamp} entry={row} journal={data.journal} client={client} showLogs={showLogs(index, timeline)} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </React.Fragment>
    );
  }
  
  return (
    <Container maxWidth="sm">
      <Box mt={1} mb={2}>
        <Content />
      </Box>
    </Container>
  )
}