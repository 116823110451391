import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";


export const Limits = () => {
  return (
    <TableContainer component={Paper}>
      <Table aria-label="simple table">
      {/* <Table className={classes.table} aria-label="simple table"> */}
        <TableHead>
          <TableRow>
            <TableCell>Limit</TableCell>
            <TableCell>Description</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>Build</TableCell>
            <TableCell>Max 10 minute build time.</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Memory</TableCell>
            <TableCell>Max 384MiB.</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Branch</TableCell>
            <TableCell>Only the repository's default branch is currently supported</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Concurrency</TableCell>
            <TableCell>Max 4 concurrent connections per repository</TableCell>
          </TableRow>
          <TableRow>
            <TableCell>HTTP Requests</TableCell>
            <TableCell>
              <Table aria-label="simple table">
                {/* <TableRow>
                  Max 4 concurrent connections per repository
                </TableRow> */}
                <TableRow>
                  Max 1,000 requests per 15 minutes
                </TableRow>
                <TableRow>
                  Max 5,000 requests per 24 hours
                </TableRow>
                <TableRow>
                  Max 50,000 requests per 30 days
                </TableRow>
              </Table>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>Uptime</TableCell>
            <TableCell>Container lifecycle is automatically managed.<br />Do not expect to run background tasks in it.</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
};