import { AppBar, Toolbar, Typography, IconButton, Button, Avatar, Menu, MenuItem, Box, Tooltip, getIconButtonUtilityClass, Paper, Hidden } from "@mui/material";
import { makeStyles, createStyles } from '@mui/styles';

import { Link, useNavigate } from "react-router-dom";
import { getAuth, User } from "firebase/auth";
import React from "react";
// import { hub_domain, isHub, signOut } from "./AppContext";

function LoginButton({ user }: { user: User | null }) {
  // const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();

  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setAuth(event.target.checked);
  // };

  const auth = getAuth();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleLogout = () => {
    setAnchorEl(null);

    const auth = getAuth();
    auth.signOut();
    // signOut(navigate)
    // navigate("/logout")
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (user) {
    return (
      <Box sx={{ flexGrow: 0 }}>
        <Tooltip title="Open settings">
          <IconButton onClick={handleMenu} sx={{ p: 0 }}>
            <Avatar> {user.email?.[0]} </Avatar>
          </IconButton>
        </Tooltip>
        <Menu
          sx={{ mt: '45px' }}
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleLogout}>Logout</MenuItem>
        </Menu>
      </Box>
    );
  }

  const onClickLogin = (event: React.MouseEvent<HTMLElement>) => {
    // if (!isHub) {
    //   window.location.assign(`${hub_domain}#/login`);
    //   return;
    // }
    // https://ui.dev/react-router-programmatically-navigate/
    navigate("/login");
    // setToLogin(true);
  };

  return (
    <Button color="inherit" onClick={onClickLogin}>Login</Button>
  )
}

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  // menuButton: {
  //   marginRight: options..spacing(2),
  // },
  title: {
    flexGrow: 1,
  },
});

export function Navigation({ user }: { user: User | null }) {
  const classes = useStyles();

  return(
    <Box sx={{ flexGrow: 1 }}>
      <AppBar position="sticky" sx={{ padding: "0.2em 4em" }}>
        <Toolbar>
          {/* <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton> */}
          {/* <Typography variant="h6" className={classes.title}> */}
          {/* <Typography variant="h6" className={classes.title}>
            dotrun
          </Typography> */}
          <Button color="inherit" href="/">foobar.run</Button>
          <Box sx={{ flexGrow: 1, height: 55, overflow: "hidden" }}>
            {/* <IconButton>
              <Avatar
                alt="dotrun"
                src="/dotrun.png"
                imgProps={{ sx: { "transform": "scale(1.8)" } }}
                sx={{ height: 100, width: 100 }}
              />
            </IconButton> */}
             {/* <Paper elevation={0} sx={{ width: 330 }}> */}
            {/* <div style={{ borderRadius: 4, width: 240, height: 55, overflow: "hidden" }}>
              <Link to={"/"}>
                foobar.run
              </Link>
            </div> */}
             {/* </Paper> */}
          </Box>
          {/* <IconButton
            aria-label="home"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            color="inherit"
            href="https://foobar.run"
          >
            <HomeIcon />
          </IconButton> */}
          <LoginButton user={user} />
        </Toolbar>
      </AppBar>
    </Box>
  )
}