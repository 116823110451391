import { ApolloClient, gql, NormalizedCacheObject, useMutation } from "@apollo/client";
import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogContentText, LinearProgress, Stack, TextField, Typography } from "@mui/material";
import { getAuth, sendSignInLinkToEmail } from "firebase/auth";

import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { MagicLinkResponse } from "../../generated/graphql";
import { AppContext } from "../AppContext";

const auth = getAuth();

// const actionCodeSettings = {
//   // URL you want to redirect back to. The domain (www.example.com) for this
//   // URL must be in the authorized domains list in the Firebase Console.
//   url: 'https://www.foobar.run/#/loginLink',
//   // This must be true.
//   handleCodeInApp: true,
// };

const MUTATION_MAGIC_LINK = gql`
  mutation fct($email: String!) {
    magicLink(email: $email) {
      message
    }
}
`;

export type LoginEmailLinkProps = {
  client: ApolloClient<NormalizedCacheObject>;
};

// https://firebase.google.com/docs/auth/web/email-link-auth?authuser=0
export function LoginEmailLink({ client }: LoginEmailLinkProps) {
  const context = useContext(AppContext);
  const [open, setOpen] = React.useState(true);
  const [email, setEmail] = React.useState("");
  const [sent, setSent] = React.useState(false);
  const [sending, setSending] = React.useState(false);

  const [magicLink] = useMutation<MagicLinkResponse>(MUTATION_MAGIC_LINK, { client });

  const navigate = useNavigate();

  const handleClose = () => {
    setOpen(false);
    navigate(-1);
  };

  const handleCancel = () => {
    setOpen(false);
    navigate(-1);
  };

  const handleOnChange: React.ChangeEventHandler<HTMLInputElement>
    = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }

  const sendSignInLink = async () => {
    setSending(true);

    // todo [ahmed.kamel] handle magicLink send error
    const ret = await magicLink({ variables: { email } });

    window.localStorage.setItem('emailForSignIn', email);
    setSent(true);
    // sendSignInLinkToEmail(auth, email, actionCodeSettings)
    //   .then(() => {
    //     // The link was successfully sent. Inform the user.
    //     // Save the email locally so you don't need to ask the user for it again
    //     // if they open the link on the same device.
    //     window.localStorage.setItem('emailForSignIn', email);
    //     setSent(true);
    //   })
    //   .catch((error) => {
    //     // todo [ahmed.kamel] show error message
    //     // const errorCode = error.code;
    //     // const errorMessage = error.message;
    //     // ...
    //   });
  };

  // todo [ahmed.kamel] show msg, we sent you an email... + progress while sending
  // return <LinearProgress />;
  const content = (() => {
    if (sent) {
      return (
        <React.Fragment>
          <DialogContent>
            <DialogContentText>
              <Stack spacing={4}>
                <Avatar
                  src="/magic_sent.png"
                  sx={{ width: 220, height: 220, margin: "auto" }}
                />
                <Typography variant="h3" component="div" gutterBottom>
                  Check your email
                </Typography>
                <Typography variant="h5" component="div" gutterBottom>
                  We sent an email to you at {email}. It has a magic link that'll sign you in.
                </Typography>
              </Stack>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>OK</Button>
          </DialogActions>
        </React.Fragment>
      )
    }

    if (sending) {
      return (
        <DialogContent>
          <DialogContentText>
            <Stack spacing={4}>
              <Typography variant="h3" component="div" gutterBottom>
                Sending...
              </Typography>
              <LinearProgress sx={{ width: 400 }}/>
            </Stack>
          </DialogContentText>
        </DialogContent>
      );
    }

    return (
      <React.Fragment>
        <DialogContent>
          <DialogContentText>
            <Stack spacing={4}>
              <Avatar
                src="/magic.png"
                sx={{ width: 220, height: 220, margin: "auto" }}
              />
              <Typography variant="h3" component="div" gutterBottom>
                Too many passwords?
              </Typography>
              <Typography variant="h5" component="div" gutterBottom>
                Get a magic link sent to your email that'll sign you in instantly!
              </Typography>
              <TextField
                autoFocus
                // margin="dense"
                id="email"
                label="Email Address"
                type="email"
                value={email}
                fullWidth
                variant="standard"
                onChange={handleOnChange}
                inputProps={{style: {fontSize: "1.2rem"}}} // font size of input text
              />
            </Stack>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button onClick={sendSignInLink}>Send Magic Link</Button>
        </DialogActions>
      </React.Fragment>
    );
  })();

  return (
    <Dialog open={open} onClose={handleClose}>
      { content }
    </Dialog>
  );
}