import { List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Chip, Tooltip } from "@mui/material";
import ListAltIcon from '@mui/icons-material/ListAlt';
import GitHubIcon from '@mui/icons-material/GitHub';
import RunCircleIcon from '@mui/icons-material/RunCircle';
import NewspaperIcon from '@mui/icons-material/Newspaper';

const exClick = (value: string) => {
  const href = value.replace("github.com", "foobar.run");
  
  window.location.assign(href);
};

const list = [
  { remote: "https://github.com/u1i/docker-hello", tags: [ "nodejs" ] },
  { remote: "https://github.com/a7medkamel/dotrun-example", tags: [ "nodejs" ] },
  { remote: "https://github.com/deric/docker-nodejs-helloworld", tags: [ "nodejs" ] },
  { remote: "https://github.com/adamhalasz/docker-node-example", tags: [ "nodejs" ] },
  { remote: "https://github.com/digitalocean/sample-dockerfile", tags: [ "nodejs" ] },
  { remote: "https://github.com/thepeaklab/react-docker-multi-stage-example", tags: [ "nodejs", "react" ] },
  { remote: "https://github.com/abankspdx/rust-api", tags: [ "rust" ] },
  { remote: "https://github.com/petkivim/nodejs-rest-api-example", tags: [ "nodejs", "api" ] },
  { remote: "https://github.com/thejungwon/docker-reactjs", tags: [ "nodejs", "react" ] },
  // https://github.run/azure/git-rest-api/repos/github.com%2Foctocat%2FHello-World/contents
];

export const Examples = () => {
  return (
    // <List className={classes.root}>
    <List>
      {list.map((value) => {
        const { remote, tags } = value;
        const labelId = `checkbox-list-label-${remote}`;
        const text = remote.replace("https://", "");

        return (
          <ListItem key={remote} role={undefined} button onClick={() => exClick(remote)}>
            <ListItemText id={labelId} primary={text} />
            <ListItemSecondaryAction>
              {tags.map((tag) => {
                  return (
                    <Chip label={tag} variant="outlined" />
                  )
              })}
              <Tooltip title="Run">
                <IconButton edge="end" href={remote.replace("github.com", "foobar.run")}>
                  <RunCircleIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Git Source">
                <IconButton edge="end" href={remote}>
                  <GitHubIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Build Logs">
                {/* <IconButton edge="end" href={remote.replace(".com", ".build")}> */}
                <IconButton edge="end" href={remote.replace("github.com", "foobar.build")}>
                  <NewspaperIcon />
                </IconButton>
              </Tooltip>
            </ListItemSecondaryAction>
          </ListItem>
        );
      })}
    </List>
  )
};