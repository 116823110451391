import React from "react";

import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button } from "@material-ui/core";

export interface EmailDialogProps {
  onConfirm: (email: string, password: string) => void;
  onCancel: () => void;
}

export function EmailPasswordDialog({ onConfirm, onCancel }: EmailDialogProps) {
  const [open, setOpen] = React.useState(true);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const handleClose = () => {
    setOpen(false);
    onCancel();
  };

  const handleCancel = () => {
    setOpen(false);
    onCancel();
  };

  const handleConfirm = () => {
    setOpen(false);
    onConfirm(email, password);
  };

  const handleOnEmailChange: React.ChangeEventHandler<HTMLInputElement>
    = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }

  const handleOnPasswordChange: React.ChangeEventHandler<HTMLInputElement>
    = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value);
  }
  
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Please confirm your email</DialogTitle>
      <DialogContent>
        <DialogContentText>
          To perform advanced actions you will need to be signed in. We will send you a Sign in Link to your email.
        </DialogContentText>
        <TextField
          autoFocus
          id="email"
          label="Email Address"
          type="email"
          value={email}
          fullWidth
          // variant="standard"
          onChange={handleOnEmailChange}
        />
        <TextField
          id="password"
          label="Password"
          type="password"
          value={password}
          fullWidth
          onChange={handleOnPasswordChange}
          autoComplete="current-password"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel}>Cancel</Button>
        <Button onClick={handleConfirm}>Confirm</Button>
      </DialogActions>
    </Dialog>
  )
}