import { getAuth, User } from 'firebase/auth';
import React from 'react';

// import {
//   GoogleReCaptchaProvider,
// } from 'react-google-recaptcha-v3';

import {
  Outlet,
} from "react-router-dom";
// import { onAuthStateChanged } from './components/AppContext';
import { Navigation } from './components/Navigation';

function Page() {
  const auth = getAuth();

  // const [user, setUser] = React.useState(auth.currentUser);
  const [user, setUser] = React.useState<User | null>(null);
  React.useEffect(() => {
    if (auth.currentUser) {
      setUser(auth.currentUser);
    }
    
    auth.onAuthStateChanged((user) => {
      setUser(user);
    });
    // onAuthStateChanged(setUser);
  });

  return(
    <React.StrictMode>
      <Navigation user={user}/>
      {/* <GoogleReCaptchaProvider
        reCaptchaKey="6Lem59AbAAAAABeJP-Hg0NktdlMuphvJhZkO6qAp"
        scriptProps={{ async: true }}>
        <ReCaptchaComponent />
      </GoogleReCaptchaProvider> */}
      {/* <Content></Content> */}
      <Outlet />
    </React.StrictMode>
  )
}

export default Page;
