import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button } from "@material-ui/core";

export interface EmailConfirmationDialogProps {
  onConfirm: (email: string) => void;
  onCancel: (email: string) => void;
}

export function EmailConfirmationDialog({ onConfirm }: EmailConfirmationDialogProps) {
  const [open, setOpen] = React.useState(true);
  const [email, setEmail] = React.useState("");

  const handleClose = () => {
    setOpen(false);
    onConfirm(email);
  };

  const handleOnChange: React.ChangeEventHandler<HTMLInputElement>
    = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  }
  
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>Please confirm your email</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Looks like you tried logging in from a different device. Please confirm your email address.
        </DialogContentText>
        <TextField
          autoFocus
          // margin="dense"
          id="email"
          label="Email Address"
          type="email"
          value={email}
          fullWidth
          variant="standard"
          onChange={handleOnChange}
        />
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={handleClose}>Cancel</Button> */}
        <Button onClick={handleClose}>Confirm</Button>
      </DialogActions>
    </Dialog>
  )
}