import { getAuth, isSignInWithEmailLink, signInWithEmailLink, connectAuthEmulator, signInWithEmailAndPassword, setPersistence, browserLocalPersistence, sendSignInLinkToEmail } from "firebase/auth";

import React, { useContext } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { AppContext } from "../AppContext";
import { EmailConfirmationDialog } from './EmailConfirmationDialog';

const auth = getAuth();

export interface LoginLinkProps {
  emailLink: string;
  // redirect: string;
}

// https://firebase.google.com/docs/auth/web/email-link-auth?authuser=0
// let email = window.localStorage.getItem('emailForSignIn');
export function LoginLink({ emailLink }: LoginLinkProps) {
  const [email, setEmail] = React.useState<string | null>(window.localStorage.getItem('emailForSignIn'));

  const context = useContext(AppContext);

  const navigate = useNavigate();

  if (!isSignInWithEmailLink(auth, emailLink)
    || context.environment === 'emulator') {
    return (
      <Navigate to="/" />
    )
  }

  const onCancel = () => {
    navigate(-1);
  };

  // Additional state parameters can also be passed via URL.
  // This can be used to continue the user's intended action before triggering
  // the sign-in operation.
  // Get the email if available. This should be available if the user completes
  // the flow on the same device where they started it.
  if (!email) {
    // email = window.prompt('Please provide your email for confirmation');
    // User opened the link on a different device. To prevent session fixation
    // attacks, ask the user to provide the associated email again. For example:
    return (
      <EmailConfirmationDialog onConfirm={setEmail} onCancel={onCancel}></EmailConfirmationDialog>
    );
  }

  // The client SDK will parse the code from the link for you.
  signInWithEmailLink(auth, email, emailLink)
    .then((result) => {
      // Clear email from storage.
      // todo [ahmed.kamel] should use cross-storage for this
      window.localStorage.removeItem('emailForSignIn');
      // window.location.assign(`${hub_domain}`);
      // navigate(-1);
      // You can access the new user via result.user
      // Additional user info profile not available via:
      // result.additionalUserInfo.profile == null
      // You can check if the user is new or existing:
      // result.additionalUserInfo.isNewUser
    })
    .catch((/*error*/) => {
      // Some error occurred, you can inspect the code: error.code
      // Common errors could be invalid email and invalid or expired OTPs.
    });

  navigate(-1);

  return (
    <Navigate to="/" />
  )
}