export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type AllocateResponse = {
  __typename?: 'AllocateResponse';
  target: RunTarget;
};

export type BuildLog = {
  __typename?: 'BuildLog';
  payload?: Maybe<Scalars['String']>;
  timestamp: Scalars['Float'];
};

export type BuildLogsResponse = {
  __typename?: 'BuildLogsResponse';
  logs: Array<BuildLog>;
};

export type JournalResponse = {
  __typename?: 'JournalResponse';
  build?: Maybe<JournalResponseBuild>;
  details?: Maybe<RunEntry>;
  state: RunState;
  target: RunTarget;
  timeline: Array<Maybe<TimelineEntry>>;
};

export type JournalResponseBuild = {
  __typename?: 'JournalResponseBuild';
  at: Scalars['Float'];
  id: Scalars['String'];
  locked: Scalars['Boolean'];
};

export type LogResponse = {
  __typename?: 'LogResponse';
  url: Scalars['String'];
};

export enum LogType {
  Build = 'Build',
  Container = 'Container',
  Deploy = 'Deploy'
}

export type MagicLinkResponse = {
  __typename?: 'MagicLinkResponse';
  message: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['String']>;
  allocate: AllocateResponse;
  magicLink: MagicLinkResponse;
};


export type MutationAllocateArgs = {
  branch?: InputMaybe<Scalars['String']>;
  remote: Scalars['String'];
};


export type MutationMagicLinkArgs = {
  email: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  _empty?: Maybe<Scalars['String']>;
  buildlogs: BuildLogsResponse;
  journal: JournalResponse;
  log: LogResponse;
};


export type QueryBuildlogsArgs = {
  buildId: Scalars['String'];
};


export type QueryJournalArgs = {
  branch: Scalars['String'];
  remote: Scalars['String'];
};


export type QueryLogArgs = {
  id: Scalars['String'];
};

export type RunEntry = {
  __typename?: 'RunEntry';
  kind: RunKind;
};

export enum RunKind {
  Build = 'Build',
  Deploy = 'Deploy',
  Failure = 'Failure',
  Start = 'Start',
  Success = 'Success',
  Timeout = 'Timeout'
}

export enum RunState {
  Abandoned = 'Abandoned',
  Completed = 'Completed',
  Failed = 'Failed',
  InProgress = 'InProgress',
  Unknown = 'Unknown'
}

export type RunTarget = {
  __typename?: 'RunTarget';
  branch: Scalars['String'];
  remote: Scalars['String'];
};

export type TimelineEntry = {
  __typename?: 'TimelineEntry';
  data: RunEntry;
  id: Scalars['String'];
  timestamp: Scalars['Float'];
};
